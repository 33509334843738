import React, {Component} from 'react';
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import {TableCell, TableHead} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import * as apiConnection from "../../../../services/apiConnection";
import {connect} from "react-redux";
import keycloak from "../../../../index";

class VirusDetailsTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            antivirus: []
        }
    }

    componentDidMount() {
        const stixPath = this.props.mifrFilePath;
        this.getAntivirusData(stixPath);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ( this.props.currentTid !== prevProps.currentTid ) {
            console.log("Componented did Update. Related Files Tab")
            console.log("TID of dropped file clicked on: ", this.props.currentTid);
            console.log("Path of dropped file clicked on: ", this.props.mifrFilePath)
            const stixPath = this.props.mifrFilePath;
            this.getAntivirusData(stixPath);
        }
    }

    getAntivirusData(stixPath) {
        const apiUrl = apiConnection.getMifrReport();
        const filePath = { tid: this.props.currentTid };
        fetch(apiUrl, {
            method: 'PUT',
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + keycloak.token },
            body: JSON.stringify(filePath)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.results && responseJson.results !== null) {
                    let antivirusData = []
                    const STIX_SECTION = "stix";
                    const ANTIVIRUS_SECTION = "Antivirus";
                    responseJson.results.map((data) => {
                        // console.log("Virus", data[STIX_SECTION][ANTIVIRUS_SECTION]);
                        if (data[STIX_SECTION] && data[STIX_SECTION][ANTIVIRUS_SECTION]) {
                            antivirusData = data[STIX_SECTION][ANTIVIRUS_SECTION][0];
                        }
                    })
                    this.setState({antivirus: antivirusData});
                }

            })
            .catch ((error) => {
                console.error("Error calling: " + apiUrl, error);
            })
    }

    render() {
        const antivirusData = this.state.antivirus;
        return (
            <>
                <h3>Antivirus</h3>
                <TableContainer id={'related-files'}>
                    <Table aria-label="antivirus table" size='small' >
                        <TableHead >
                            <TableRow >
                                <TableCell>Engine</TableCell>
                                <TableCell>Signature</TableCell>
                                <TableCell>Last Signature Update (Z)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {antivirusData &&
                            antivirusData.map((data, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{data['name']}</TableCell>
                                        <TableCell>{data['threat_found']}</TableCell>
                                        <TableCell>{data['def_time']}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentTid: state.analysisReducer.currentTinfo ? state.analysisReducer.currentTinfo.tid: '',
        mifrFilePath: state.analysisReducer.mifrFilePath
    }
}

export default connect(mapStateToProps, null)(VirusDetailsTab);