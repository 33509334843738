import React, {Component} from 'react';
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import {TableCell, TableHead} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Button from '@material-ui/core/Button';
import * as apiConnection from "../../../../services/apiConnection";
import {DropFileModel} from "../../MifrReportPage/DropFileModel";
import "../../../../assets/css/FileAnalysisReport/RelatedFilesTab.css";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import keycloak from "../../../../index";
import getFormData from '../../../SubmissionForm/utils/getFormDataForSubmission';
import * as actionTypes from "../../../../redux/actions/analysisActions";

const verdicts = ["malicious", "suspicious", "clean", "not_available"]
class RelatedFilesTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            droppedFiles: [],
            maliciousDroppedFiles: [],
            suspiciousDroppedFiles: [],
            cleanDroppedFiles: [],
            naDroppedFiles: [],
            nullDroppedFiles: [],
            dropFileCnt: null,
            submitPressed: "",
            submissionId: ""
        }

        this.reloadFARPage = this.reloadFARPage.bind(this);
    }

    clearDroppedFiles() {
        this.setState({
            maliciousDroppedFiles: [],
            suspiciousDroppedFiles: [],
            cleanDroppedFiles: [],
            naDroppedFiles: [],
            nullDroppedFiles: [],
            // dropFileCnt: null
        })
    }

    componentDidMount() {
        const stixPath = this.props.mifrFilePath;
        this.checkIfDroppedFilesExist(stixPath, this.props.currentTid);
        this.getSubmissionId();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ( this.props.currentTid !== prevProps.currentTid ) {
            // console.log("Componented did Update. Related Files Tab")
            console.log("TID of dropped file clicked on: ", this.props.currentTid);
            console.log("Path of dropped file clicked on: ", this.props.mifrFilePath);
            this.checkIfDroppedFilesExist(this.props.mifrFilePath, this.props.currentTid);
            this.getSubmissionId();
        }
    }

    // after GA, this should be done in the database
    getVerdictColor(verdict) {
        switch (verdict) {
            case 'malicious':
                return "#99001A";
            case 'suspicious':
                return "#C29C28";
            case 'clean':
                return "#669900";
            case 'not_available':
                return "#8B7E74";
            default:
                return;
        }
    }

    getSubmissionId() {
        let input = {
            "delta": -(new Date().getTimezoneOffset()),
            "sid": this.props.sid
        }

        fetch(apiConnection.getSubmissionsListUrl(), {
            method: "PUT",
            headers: {"Content-Type": "application/json",  'Authorization': 'Bearer ' + keycloak.token},
            body: JSON.stringify(input),
            credentials: "same-origin"
          })
            .then(res => res.json())
            .then(res => {
                if(res && res[0]) {
                    this.setState({submissionId: res[0].submission_id});
                }
            })
            .catch(err => console.error("Fetch Submission : " + err)); 
    }

    checkIfDroppedFilesExist(stixPath) {
        const apiUrl = apiConnection.getMifrReport();
        const body = { tid: this.props.currentTid };
        fetch(apiUrl, {
            method: 'PUT',
            headers: { "Content-Type": "application/json",  'Authorization': 'Bearer ' + keycloak.token},
            body: JSON.stringify(body)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.results && responseJson.results !== null) {
                    responseJson.results.map((data) => {
                        if (data.stix['Drops'].length > 0) {
                            console.log("drops exist!!")
                            this.getTransactionData("");
                        }
                    })
                }
            })
            .catch ((error) => {
                console.error("Error calling: " + apiUrl, error);
            })
    }
    
    handleVerdictValue(verdict) {
        // if verdict is null, return empty string
        if(!verdict) {
            return " ";
        } if(verdict==="not_available") {
            verdict="N/A";
        }
        return verdict.toUpperCase();
    }

    handleVerdict(droppedFileObj) {
        switch(droppedFileObj.verdict) {
            case 'malicious':
                this.state.maliciousDroppedFiles.push(new DropFileModel(droppedFileObj));
                break;
            case 'suspicious':
                this.state.suspiciousDroppedFiles.push(new DropFileModel(droppedFileObj));
                break;
            case 'clean':
                this.state.cleanDroppedFiles.push(new DropFileModel(droppedFileObj));
                break;
            case 'not_available':
                this.state.naDroppedFiles.push(new DropFileModel(droppedFileObj));
                break;
            case null:
                this.state.nullDroppedFiles.push(new DropFileModel(droppedFileObj));
                break;
            default:
                return;
        }
    }

    handleDropFileCnt(verdictOrTotal) {
        switch(verdictOrTotal) {
            case 'malicious':
                return this.state.dropFileCnt.maliciousCount;
            case 'suspicious':
                return this.state.dropFileCnt.suspiciousCount;
            case 'clean':
                return this.state.dropFileCnt.cleanCount;
            case 'not_available':
                return this.state.dropFileCnt.unknownCount;
            case 'total':
                return this.state.dropFileCnt.totalCount;
            case null:
                break;
            default:
                return;
        }       
    }

    getTransactionData(TIDofSubmitPressed) {
        this.clearDroppedFiles();
        const apiUrl = apiConnection.getTranMetaListUrl();
        const body = {
            sid: this.props.sid,
            delta: -(new Date().getTimezoneOffset()),
            limit: "99",
            order_by: "created_ts",
            file_metadata: "True"
        }

        fetch(apiUrl, {
            method: 'PUT',
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + keycloak.token},
            body: JSON.stringify(body)
        }).then(response => response.json())
            .then(responseJson => {
                if (responseJson['transaction_files'].length > 0) {
                    let fileData = responseJson['transaction_files'];
                    
                    if(TIDofSubmitPressed) {
                        this.handleSubmitPress(TIDofSubmitPressed, fileData);
                    }

                    fileData.map((data, index) => {

                        Object.keys(data).map(key => {

                            // get counts
                            if(key===this.props.currentTid) {
                                // convert string to JSON
                                let dropFileCnt = JSON.parse(data[this.props.currentTid]['transaction_file_data']['drop_file_cnt']);
                                this.setState({dropFileCnt: dropFileCnt});
                            }

                            else if(data[key]['transaction_file_data']['drop_file_pid'] === this.props.currentTid) {
                                let droppedFileObj = {
                                    sid: data[key]['transaction_file_data']['sid'],
                                    tid: data[key]['transaction_file_data']['tid'],
                                    fileName: data[key]['transaction_file_data']['original_file_name'],
                                    verdict: data[key]['transaction_file_data']['threat_score'],
                                    dropLevel: data[key]['transaction_file_data']['drop_file_level'],
                                    sha256: data[key]['file_metadata']['sha256'],
                                    submittedDate: data[key]['transaction_file_data']['created_ts'],
                                    threat: data[key]['transaction_file_data']['threat_score'],
                                    status: data[key]['transaction_file_data']['transaction_status_cd']
                                }
                            
                                this.handleVerdict(droppedFileObj);
                                // droppedFiles.push(new DropFileModel(droppedFileObj));
                            }
                        })
                    });
                }
                // order dropped files according to verdict
                let orderedDroppedFiles = [...this.state.maliciousDroppedFiles, ...this.state.suspiciousDroppedFiles, ...this.state.cleanDroppedFiles, ...this.state.naDroppedFiles, ...this.state.nullDroppedFiles];
                this.setState({droppedFiles: orderedDroppedFiles});
            })
            .catch ((error) => {
                console.error("Error calling: " + apiUrl, error);
            })
    }

    reloadFARPage(tinfo) {
        this.clearDroppedFiles();
        this.setState({droppedFiles: []});

        // retrieve file path of dropped file TID that was clicked

        const apiUrl = apiConnection.getReportAnalysisUrl(this.props.currentSid);
        fetch(apiUrl, {
            method: 'GET',
            headers: { "Content-Type": "application/json",  'Authorization': 'Bearer ' + keycloak.token},
            credentials: "same-origin"
        }).then(res => res.json())
            .then(resJson => {
                if (!resJson.errors.length) {
                        let reportMetaDataForSid = resJson.results;
                        let stixData = reportMetaDataForSid.find(obj => {
                            return obj.type === "STIX21" && obj.tid === tinfo.getTID();
                        })
                        let pathOfDroppedFile = stixData.location + stixData.file_name;
                        this.props.onAddTidToReportBreadcrumbs(tinfo, pathOfDroppedFile);
                        this.getSubmissionId();
                } else {
                    console.error("Report Analysis returned error: " + JSON.stringify(resJson.errors[0]), null, 2 );
                }
            })
            .catch ((error) => {
                console.error("Error calling: " + apiUrl, error);
            })
        return true;
    }

    submitDroppedFile(dropFileTID, filePath) {
        let data = {};
        data.user_id = keycloak.tokenParsed.preferred_username;
        data.email = keycloak.tokenParsed.email;
        data.first_name = keycloak.tokenParsed.given_name;
        data.last_name = keycloak.tokenParsed.family_name;
        data.organization = keycloak.tokenParsed.organization_name;
        data.phone_number = keycloak.tokenParsed.phone_number;
        data.disclaimer_ind = "1";
        data.sid = this.props.sid;
        data.submission_id = this.state.submissionId;
        
        // for dropped files only
        let sourceIdJSON = "{'dropFilePID': '" + this.props.currentTid + "', 'TID': '" + dropFileTID + "'}";
        data.source_id = sourceIdJSON;
        data.source = "drop_file";
        data.files = filePath;

        const formData = getFormData(data);

        fetch(apiConnection.submitDroppedFile(), {
          method: "POST",
          headers: {'Authorization': 'Bearer ' + keycloak.token},
          body: formData,
          credentials: "same-origin"
        })
          .then(res => res.json())
              .then(res => {
                // dropped file submitted
                if(res.message === "Success!") {
                    this.getTransactionData("");
                } 
                else if(res.errors) {
                    console.log(res.errors[0]);
                }
              })
              .catch(err => console.error("Fetch Submission : " + err));
    }

    submitTransactionFile(dropFileTID, filePath, fileObj) {
        fileObj.transaction_status_cd = "Submitted";
        let input = fileObj;

        fetch(apiConnection.createTranData(), {
            method: "PUT",
            headers: {"Content-Type": "application/json",  'Authorization': 'Bearer ' + keycloak.token},
            body: JSON.stringify(input),
            credentials: "same-origin"
          })
            .then(res => res.json())
            .then(res => {
              this.submitDroppedFile(dropFileTID, filePath);
            })
            .catch(err => console.error("Fetch Submission : " + err)); 
    }

    handleSubmitPress(dropFileTID, fileData) {
        let rawDataForDroppedFile = fileData.find(obj => {
            return Object.keys(obj)[0] === dropFileTID;
        })

        let rawData = Object.values(rawDataForDroppedFile)[0].transaction_file_data;

        // if status is "Not Submitted", then submit dropped file
        if(rawData.transaction_status_cd === "Not Submitted") {
            let filePath = rawData.file_location + "/" + rawData.file_name;
            this.submitTransactionFile(dropFileTID, filePath, rawData);
        } 
        rawData = {};
    }

    render() {
        const droppedFiles = this.state.droppedFiles.length > 10 ? this.state.droppedFiles.slice(0,9) : this.state.droppedFiles;
        return (
            <>
                <TableContainer id={'related-files'}>
                    <Table aria-label="related files table" size='small' >
                        <TableHead >
                            <TableRow >
                                <TableCell>TID</TableCell>
                                <TableCell>SHA-256</TableCell>
                                <TableCell>Filename</TableCell>
                                {/* <TableCell align={'center'}>Drop Level</TableCell> */}
                                <TableCell>Status</TableCell>
                                <TableCell align={'center'}>Verdict</TableCell>
                                <TableCell align={'center'}>Manual Submission</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {droppedFiles &&
                            droppedFiles.map((file, index) => {
                                let verdict = file.getVerdict();
                                let verdictColor = this.getVerdictColor(verdict);
                                let status = file.status;
                                
                                return (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {
                                                status==="Completed" 
                                                ?
                                                    <Link to={'/report/'} onClick={() => this.reloadFARPage(file)}>
                                                        {file.getTID()}
                                                    </Link>
                                                :
                                                    file.getTID()
                                            }
                                        </TableCell>
                                        {/* <TableCell>{file.getTID()}</TableCell> */}
                                        <TableCell>{file.getSha256()}</TableCell>
                                        <TableCell style={{wordWrap: "break-word", maxWidth: "300px"}}>{file.getFileName()}</TableCell>
                                        {/* <TableCell align={"center"}>{file.getDropLevel()}</TableCell> */}
                                        <TableCell>{file.status}</TableCell>
                                        <TableCell align={'center'}>
                                            <div className="verdict" style={{background: verdictColor}} >
                                                {this.handleVerdictValue(verdict)}
                                            </div>
                                        </TableCell>
                                        <TableCell align={"center"}>  
                                       { (status === 'Not Submitted') ?
                                            <Button 
                                                key={index}
                                                className="drop-file-sub-btn"
                                                onClick={()=>this.getTransactionData(file.getTID())}
                                            >
                                                S<span style={{textTransform: "lowercase"}}>ubmit</span>
                                            </Button>
                                        :
                                            <Button className="drop-file-sub-btn disable" disabled>
                                                S<span style={{textTransform: "lowercase"}}>ubmit</span>
                                            </Button>
                                        } 
                                    </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {droppedFiles.length>0 &&
                    <div className="verdicts-summary">
                        <div className="dropped-files-count">
                            <span>{this.state.dropFileCnt.totalCount}</span>
                            <span>&nbsp; DROPPED FILE{droppedFiles.length === "1" ? "" : "S"}</span>
                        </div>
                        {verdicts.map( (verdict, index) => 
                            <div key={index} className="verdict-count" style={{background: this.getVerdictColor(verdict)}}>
                                <span>{verdict ? this.handleDropFileCnt(verdict) : this.handleDropFileCnt('total')}</span>
                                <span>&nbsp; {this.handleVerdictValue(verdict)}</span>
                            </div>
                        )}
                    </div>
                }
            </>
        )
    }
}

const mapDispatchToProps = dispatch =>{
    return {
        onAddTidToReportBreadcrumbs: (tinfoObj, pathOfDroppedFile) => dispatch({type: actionTypes.BREADCRUMBS_ADD_TID, tinfo: tinfoObj, filePath: pathOfDroppedFile}),
    }
}

const mapStateToProps = state => {
    return {
        currentTid: state.analysisReducer.currentTinfo ? state.analysisReducer.currentTinfo.tid: '',
        currentSid: state.analysisReducer.currentTinfo ? state.analysisReducer.currentTinfo.sid: '',
        mifrFilePath: state.analysisReducer.mifrFilePath
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RelatedFilesTab);
