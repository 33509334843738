import React, { Component } from "react";
import QuickSubmission from "./QuickSubmission";
import RecentSubmissionTable from "./RecentSubmissionTable";
import '../../App.css'

class Home extends Component {
  constructor(props) {
    super(props);
    this.isMountedVal = 0;
    this.state = { 
      submitted: 0,
      quickSubmitMade: false
    };
    this.handleSubmitted = this.handleSubmitted.bind(this);
  }

  handleSubmitted = (isSubmittedSuccessful) => {
    if (this.isMountedVal && isSubmittedSuccessful) {
      this.setState({quickSubmitMade: true});
      let temp = this.state.submitted;
      temp++;
      this.setState({ submitted: temp });
      // this.setState({ quickSubmitPressed: true });
    }
  }

  handleQuickSubmitPressed = () => {
    this.setState({ quickSubmitPressed: false });
  }

  componentDidMount() {
    this.isMountedVal = 1;
  }

  componentWillUnmount() {
    this.isMountedVal = 0;
  }

  onQuickSubmit = () => {
    this.setState({quickSubmitMade: false})
  }

  render() {
    return (
      <div id="home-container">
        <QuickSubmission handleSubmitted={this.handleSubmitted} />
        <RecentSubmissionTable 
          submitTimes={this.state.submitted} 
          quickSubmitMade={this.state.quickSubmitMade}
          onQuickSubmit={this.onQuickSubmit}
        />
      </div>
    );
  }
}

export default Home;
