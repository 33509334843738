//React
import React, { Component } from "react";

//Services
import * as apiConnection from "../../services/apiConnection.js";
import * as httpCalls from "../../services/httpCalls.js";
import keycloak from "../../index";

//Classes
import { NewSearchReport } from "./";
import "../../App"
import NoResult from "./NoResult";
import SystemError from "../utils/SystemError";
import UserInfo from "../../utils/UserInfo"
class Search extends Component {

  constructor(props) {
    super(props);
    // passedState contains searchText from quick search on submission list page
    const passedState = this.props.location.state;

    this.state = {
      searchText: (passedState && passedState.searchText) || "",
      searchResults: [],
      queryReport: "",
      noResult: false
    };

    this.header = {
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + keycloak.token
    }
  }

  componentDidMount() {
    // passedState is the state passed from another component
    const passedState = this.props.location.state;
    if (passedState && passedState.searchText) {
      this.loadSearchData(passedState);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // passedState is the state passed from another component
    const passedState = this.props.location.state;
    if (passedState && prevProps.location.state && prevProps.location.state.searchText !== passedState.searchText) {
      this.loadSearchData(passedState);
    }
  }

  RemovingDuplicateSIDs(rows) {
    var current_sid = "";
    var new_rows = [];
    rows.sort(function (a, b) {
      return a.sid > b.sid;
    });
    for (var i = 0; i < rows.length; i++) {
      if (rows[i].sid !== current_sid) {
        current_sid = rows[i].sid;
        new_rows.push(rows[i])
      }
    }
    new_rows.sort(function (a, b) {
      return b.created_ts > a.created_ts;
    });

    return new_rows;
  };

  /* 
    these properties will be sent to /search, conditionally:
      role - one of 5 roles (check getRole())
      user_id - only for MNG and MS-ISAC Consumers
      start_date - only when date range selected
      end_date - only when date range selected
      search_entire_db: only when "Search Entire Database" checkbox selected
  */
  getSearchAPIInput = (searchData) => {
    // The sign of getTimezoneOffset() is inverted bc it returns the opposite sign.
    // If the function returns -300, it means UTC+06. So, it's inverted to 300 to match UTC+06.
    return (
      {
        role: this.getRole(),
        search_text: searchData.search_text,
        delta: -(new Date().getTimezoneOffset()),
        ...( (UserInfo().myRoles.includes("MNG Consumer") || UserInfo().myRoles.includes("MS-ISAC"))&& {user_id: UserInfo().user_id}),
        ...(searchData.start_date && {search_date: searchData.start_date}),
        ...(searchData.end_date && {end_date: searchData.end_date}),
        ...(searchData.searchEntireDatabase && {search_entire_db: true}),
      }
    )
  }

  getRole = () => {
    let role = "";
    const myRoles = UserInfo().myRoles;

    if(myRoles.includes("MS-ISAC Analyst")) {
      role = "MS-ISAC Analyst";
    } else if(myRoles.includes("MNG CISA Analyst") || myRoles.includes("MNG Admin")) {
      role = "MNG CISA Analyst";
    } else if(myRoles.includes("MNG Consumer") || myRoles.includes("MS-ISAC")) {
      role = "MNG Consumer";
    }

    return role;
  }
 
  loadSearchData = (searchParam) => {
    const searchString = searchParam.searchText;
    if (searchString=== "") {
      this.setState({ queryReport: "" });
      return;
    }  
    let apiUrl = apiConnection.getSearchResults();
    let input = this.getSearchAPIInput(searchString);

      httpCalls
        .configureAxiosRequest(apiUrl, this.header, "PUT", input)
        .then((res) => {
          if (res.data != "") {
          if (res.data.errors.length === 0) {
              let data = res.data.results;
            if (data.length === 0) {
                this.setState ({noResult: true});
                this.setState({ fz: searchString, queryReport: <NoResult /> });
              } else {
                let temp = data;
                let search_data = this.RemovingDuplicateSIDs(data);
                temp.sort((a, b) => {
                  return (new Date(b["created_ts"])).getTime() >= (new Date(a["created_ts"])).getTime() ? 1 : -1;
                });
                this.setState({ 
                  fz: searchString,
                  searchResults: temp,
                  queryReport: <NewSearchReport queryResults={search_data}/> 
                });
              }
            } else { console.log("ERROR No Data has returned.")}
          } else {
            this.setState ({noResult: true});
            this.setState({ fz: searchString, queryReport: <NoResult /> });
          }
        })
        .catch((err) => {
          console.log("Search err : " + err);
          // if (apiUrl == "AccessDenied") {
            // this.setState({ fz: searchString, queryReport: < UnauthorisedUser/> });
          // } else {
            this.setState({ searchResults: [] });
            this.setState({ fz: searchString, queryReport: <SystemError /> });
           // }
        }
      );
    // } else {
    //   this.setState({ fz: searchString, queryReport: < UnauthorisedUser/> });
    // }
  }

  render() {

    return (
      <div>
        {/*<SearchBar*/}
        {/*  fz={this.state.fz}*/}
        {/*  handleChange={this.handleChange}*/}
        {/*  handleSearch={this.handleSearch}*/}
        {/*/>*/}
        {this.state.queryReport}
      </div>
    );
  }
}

export default Search;