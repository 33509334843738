import { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import React from "react";
import Moment from 'react-moment';
import { Link } from "react-router-dom";
import { Icon, Popup } from 'semantic-ui-react'
import { getTLP } from "../SubmissionList/utils/submissionListUtils";
import TransMeta from "../../components/SubmissionList/utils/TransMetaTable";
import infoImg from "../../assets/img/info.png";
import keycloak from "../../index";
import * as apiConnection from "../../services/apiConnection.js";

export default function SearchReportRow(props) {
    const {row} = props;
    const [open, setOpen] = React.useState(false);
    const [sidStatus, setSidStatus] = useState(row.submission_status_cd);
    const [sidStatusMsg, setSidStatusMsg] = useState(row.submission_status_msg);
    const [submissionCount, setSubmissionCount] = useState(row.file_cnt);

    let handleTransMetaTable = () => {
        setOpen(!open);
    }

    let reloadSIDStatus = () => {
        let input = {
            delta: -(new Date().getTimezoneOffset()),
            "sid": row.sid
        }

        fetch(apiConnection.getSubmissionsListUrl(), {
            method: "PUT",
            headers: {"Content-Type": "application/json",  'Authorization': 'Bearer ' + keycloak.token},
            body: JSON.stringify(input),
            credentials: "same-origin"
          })
            .then(res => res.json())
            .then(res => {
                if(res && res[0]) {
                    setSidStatusMsg(res[0].status_msg);
                    setSidStatus(res[0].submission_status_cd);
                }
            })
            .catch(err => console.error("Fetch Submission : " + err)); 
    }

    useEffect(() => {
        // console.log("row: ", row)
        setSidStatus(row.submission_status_cd);
        setSidStatusMsg(row.submission_status_msg);
        getSubmissionCount(row.file_cnt);
        setOpen(false);
    }, [row])

    useEffect(() => {
        // console.log("props.quickSubmitMade: ", props.quickSubmitMade);
        setSidStatus(row.submission_status_cd);
        setSidStatusMsg(row.submission_tatus_msg);
        getSubmissionCount(row.file_cnt);
        if(props.quickSubmitMade) {
            props.onQuickSubmit();
        }
    }, [props.quickSubmitMade]);

    useEffect(() => {
        // console.log("props.pageChanged: ", props.pageChanged);
        setSidStatus(row.submission_status_cd);
        setSidStatusMsg(row.submission_status_msg);
        getSubmissionCount(row.file_cnt);
        if(props.pageChanged) {
            setOpen(false);
            props.onPageChange();
        }
    }, [props.pageChanged, props.sortStatusClicked]);

    let getSubmissionCount = (count) => {
        let submissionCountVal;
        // no files within zip or zip password is not "infected"
        if(sidStatus === "Failed" || row.submission_status_cd === "Failed") {
            submissionCountVal = 0;
        } 
        // file_cnt value exists (including value of 0 for empty zip)
        else if(count !== null) {
            submissionCountVal = count;
        } 
        // else, single file submission
        else {
            submissionCountVal = 1;
        }
        setSubmissionCount(submissionCountVal);
    }

    return (
        <React.Fragment>
            <TableRow hover>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => handleTransMetaTable()}>
                        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRight />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.created_ts}
                </TableCell>
                <TableCell>{row.sid}</TableCell>
                <TableCell>{submissionCount}</TableCell>
                {/* <TableCell>{row.first_name}</TableCell>
                <TableCell>{row.last_name}</TableCell> */}
                <TableCell>{row.organization}</TableCell>
                <TableCell>
                    {sidStatus}
                    {sidStatus==="Failed" && sidStatusMsg!==null &&
                        <Popup
                            trigger={<Icon name='info circle' className="sid-status-info-icon"/>}
                            content={sidStatusMsg}
                            position='top center'
                            style={{transform: "translate3d(0px, 0px, 0px)", width: "200px"}}
                        />
                    }
                </TableCell>
                {/* <TableCell>{row.user_id}</TableCell> */}
                <TableCell>{getTLP(row.traffic_light_protocol_cd)}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell padding={"none"} colSpan={10}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <TransMeta 
                                sid={row.sid} 
                                description={row.description}
                                reloadSIDStatus={reloadSIDStatus}
                            />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}
