import React from 'react';
import Collapse from "@material-ui/core/Collapse";
import EmailAnalysisSectionHeader from '../EmailAnalysisSectionHeader';
import {Tab} from "semantic-ui-react";
import SyntaxHighlighter from 'react-syntax-highlighter';
import {a11yLight} from "react-syntax-highlighter/dist/esm/styles/hljs";

const beautify_html = require('js-beautify').html;

const Body = ({bodyOpen, bodyData, handleBodyOpen, printClicked}) => {

  const handleHTMLBody = (htmlText) => {
    const formattedHTML = beautify_html(htmlText, {
      preserve_newlines: false,
      indent_scripts: "keep",
      space_before_conditional: false,
      end_with_newline: true,
      wrap_line_length: 200,
      indent_inner_html: true,
    });
    return formattedHTML;
  }

  const fixTextStyle = (text) => {
    return text.split("\n").map((str, i) => <p key={i}>{str}</p>);
  }

  const handleHTMLBodyRaw = (htmlText) => {
    const formattedHTMLRaw = beautify_html(htmlText, {
        "indent_size": 0,
        "indent_inner_html": true,
    });
    return formattedHTMLRaw;
  }

  // const printHTMLBody = (htmlText) => {
  //   console.log(htmlText);
  //   return "temp";
  // }

  return (
    <div className="section-container">
      <EmailAnalysisSectionHeader
        sectionId="Body"
        open={bodyOpen}
        handleClick={() => handleBodyOpen()}
      />
      <Collapse in={bodyOpen} timeout="auto" unmountOnExit>
        { (bodyData && Object.keys(bodyData).length > 0)
        ?
          <>
            <div id="email-body-tabs">
            <Tab 
              panes= {[
                { menuItem: 'Text', render: () => 
                    <Tab.Pane className="tab-pane">
                      {bodyData.text ? fixTextStyle(bodyData.text) : "No text found."}
                    </Tab.Pane> 
                },
                { menuItem: 'HTML', render: () => 
                    <Tab.Pane className={bodyData.html ? "email-body-html" : "tab-pane"}>
                      {bodyData.html 
                      ? 
                        <SyntaxHighlighter
                         language="html" 
                         style={a11yLight}
                         showLineNumbers
                        >
                          {handleHTMLBody(bodyData.html)}
                        </SyntaxHighlighter>
                      : 
                        "No HTML found."
                      }
                    </Tab.Pane>
                },
                { menuItem: 'HTML (Text Only)', render: () => 
                    <Tab.Pane className="tab-pane">
                      {bodyData.html_text_only ? bodyData.html_text_only : "No HTML (Text Only) found."}
                    </Tab.Pane> 
                },
                { menuItem: 'HTML (Raw)', render: () => 
                    <Tab.Pane className={bodyData.html ? "email-body-html raw" : "tab-pane"}>
                      {bodyData.html ? 
                      <pre>{handleHTMLBodyRaw(bodyData.html)}</pre> 
                      :  "No HTML found."}
                    </Tab.Pane> 
                }
              ]}
            />
            </div>

            {printClicked &&

            <div id="email-body-print">
              <div className="subtitle-container">
                <h5>Text</h5>
              </div>
              <div className="body-section-content">{bodyData.text ? fixTextStyle(bodyData.text) : "No text found."}</div>

              <div className="subtitle-container">
                <h5>HTML</h5>
              </div> 

              {bodyData.html 
              ?
                <div className="email-body-html body-print"> 
                  <SyntaxHighlighter
                    language="html" 
                    style={a11yLight}
                    showLineNumbers
                  >
                    {handleHTMLBody(bodyData.html)}
                  </SyntaxHighlighter>
                </div>
              : 
                <div className="body-section-content"> 
                  No HTML found.
                </div>
              }

              <div className="subtitle-container">
                <h5>HTML (Text Only)</h5>
              </div>

              <div className="body-section-content">{bodyData.html_text_only ? bodyData.html_text_only : "No HTML (Text Only) found."}</div>

              <div className="subtitle-container">
                <h5>HTML (Raw)</h5>
              </div>

              <div className="body-section-content raw">
                {bodyData.html ? 
                  <pre>{handleHTMLBodyRaw(bodyData.html)}</pre>
              :  "No HTML found."}
              </div>

            </div>
            }
          </>
        :
          <div className="empty-ear-section">
            No email body found.
            <hr />
          </div>
        }
      </Collapse>
    </div>
  );
}

export default Body;
