import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Icon} from "semantic-ui-react";

class Deny extends Component {
  render() {
    window.scrollTo(0,0);
    return (
      <div className="message-with-icon-container text-center">
        <Icon.Group style={{color: "rgb(161,38,38)", opacity: ".88"}}>
          <Icon name="circle outline" size="large"/>
          <Icon name="exclamation" size="small" style={{paddingRight: "3px"}} />
        </Icon.Group>
        <h1>Submission Failed</h1>
        <p>Something went wrong with your submission.</p>
        <Link to="/submission">Try Again</Link>
        <span> | </span>
        <Link to="/submissionList">View All Submissions</Link>
      </div>
    );
  }
}

export default Deny;