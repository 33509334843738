import React from "react";
import "spinkit/spinkit.css";
import {Link} from "react-router-dom";
import {Icon} from "semantic-ui-react";

function LoadingSubmissions(props) {
  const getText = () => {
    if(props.submissionType === "my") {
      return "your";
    } else if(props.submissionType === "all") {
      return "the";
    }
  }

  window.scrollTo(0,0);
  return (
    <>
    <div 
      className={
        props.submissionType==="my" 
        ? "my-submissions-loading message-with-icon-container text-center" 
        : "message-with-icon-container text-center" 
      }
    >
      <div class="sk-grid" className="submission-loader">
        <div class="sk-grid-cube"></div>
        <div class="sk-grid-cube"></div>
        <div class="sk-grid-cube"></div>
        <div class="sk-grid-cube"></div>
        <div class="sk-grid-cube"></div>
        <div class="sk-grid-cube"></div>
        <div class="sk-grid-cube"></div>
        <div class="sk-grid-cube"></div>
        <div class="sk-grid-cube"></div>
      </div>
      <h1>Hold Tight!</h1>
      <p>Please wait while we grab {getText()} submissions.</p>
    </div>
    </>
  );
}

export default LoadingSubmissions;
