import React, { Component } from "react";
import * as apiConnection from "../../../services/apiConnection.js";
import keycloak from "../../../index";
import "../../../assets/css/FileAnalysisReport/RelatedFilesTab.css";

/**
 * Dropped File component for IMIFR Summary section
 */
export class DroppedFile extends Component {
    
    constructor() {
      super();
      this.handleClick = this.handleClick.bind(this);      
    }

   // after GA, this should be done in the database
   getVerdictColor(verdict) {
    switch (verdict) {
        case 'malicious':
            return "#99001A";
        case 'suspicious':
            return "#C29C28";
        case 'clean':
            return "#669900";
        case 'not_available':
            return "#8B7E74";
        default:
            return;
    }
}

handleVerdictValue(verdict) {
  // if verdict is null, return empty string
  if(!verdict) {
      return " ";
  } if(verdict==="not_available") {
      verdict="N/A";
  }
  return verdict.toUpperCase();
}

/**
     * 
     * This method calls transaction_files API using TID as parameter.
     * It returns response from the API.
     */
    async callTransactionFilesApi(TID) {

      let response = null;

      const apiUrl = apiConnection.getTranMetaListUrl();
      const body = {
         tid: TID,
         delta: -(new Date().getTimezoneOffset()),
         limit: "1",
         order_by: "file_size",
         file_metadata: ""
      }     

      try {
        response = await fetch(apiUrl, {
          method: 'PUT',
          headers: {
            "Content-Type": "application/json",  
            'Authorization': 'Bearer ' + keycloak.token
          },
          body: JSON.stringify(body),
      
        })
      } catch (error) {
        console.error("Error calling: " + apiUrl, error);
      }

      let responseJson = await response.json();

      return responseJson;
    }

    /**
     * 
     * This method calls get_report_analysis API using sid as parameter.
     * It returns response from the API.
     */
    async callReportAnalysisApi(sid) {

      let response = null;

      const apiUrl = apiConnection.getReportAnalysisUrl(sid);

      try {
        response = await fetch(apiUrl, {
          headers: {
            'Authorization': 'Bearer ' + keycloak.token
          }
        })
      } catch (error) {
        console.error("Error calling: " + apiUrl, error);
      }

      let responseJson = await response.json();

      return responseJson;
    }

    /**
     * 
     * Re-render MIFR Page using dropped filedata. It does it by calling update method
     * from its parent.
     */
    renderMifrPage = (mifrData) => {
       this.props.update(mifrData); 
    }

    /**
     * 
     * Check if Stix report is ready. It returns true or false.
     */
    isStixReportReady(tid, reportData) {
      const completed = "Completed";
      const reportType = 'STIX21';

      var reportInfo = {
        isStixReady: false,
        stixPath: null
      }
  
      reportData.map((data) => {
        if (data.tid == tid && data.type == reportType && data.status == completed) {
          reportInfo.isStixReady = true;
          reportInfo.stixPath = data.location + data['file_name'];
        }
      });
      // console.log(
      //   "is Stix Ready?.... " + reportType + " " + reportInfo.isStixReady.toString()
      // );
      return reportInfo;
    }

    /**
     * This method is called when user clicks the drop file link (TID). 
     * This method calls transaction_files api to get sid. then it calls
     * get_report_analysis end point to get stix_path and re-render mifr page
     * using dropped file data.
     */
    handleClick() {
        
        //let TID = "578568ea-768a-438e-bcef-0d7c0f3fd822";
        let TID = this.props.TID;

        this.callTransactionFilesApi(TID).then(response => {
          
          let sid = "";

          if (response['transaction_files'].length > 0) {
            sid = response['transaction_files'][0][TID]['transaction_file_data']['sid']; 
          }

          this.callReportAnalysisApi(sid).then(response => {
             
            if (!response.errors.length) {
              // console.log('ReportAnalysisApi....  ' + JSON.stringify(response));

              let reportInfo = this.isStixReportReady(TID, response.results);
              // console.log('stixPath....' + reportInfo.stixPath);

              if (reportInfo.isStixReady) {
                var mifrData = {
                  tid: TID,
                  stixPath: reportInfo.stixPath
                };
             
                this.renderMifrPage(mifrData);
              }
            }
               
          })
        })
    }

    /**
     * Renders dropped file component in the MIFR's summary section.
     */
    render() {
      let verdict = this.props.verdict;
      let verdictColor = this.getVerdictColor(verdict);
        return (                
          <tr className="mifr-dropped-file-row">
            <td>{this.props.TID}</td>   
            <td>{this.props.Sha1}</td>
            <td>{this.props.fileName}</td>
            <td style={{textAlign: "center"}}>
              <div className="verdict" style={{background: verdictColor}} >
                {this.handleVerdictValue(verdict)}
              </div>
            </td>
          </tr>
        );
    }
}