import React from "react";
import { Button, Navbar as NavBar } from "react-bootstrap";
import cisaLogoImg from "../../assets/img/logo/cisa_logo.png";
import malwareLogoImg from "../../assets/img/logo/malware_logo_white.png";
import { logout } from "../../../src/utils/Logout";
import { messagePermission } from '../../utils/PermissionUtil.js';

const NoAccountPage = () => {

    const handleLogout = () => {
        logout();
    }

    return (
        <div>
            <NavBar fixed="top" expand="md" bg="coz" variant="dark">
                <NavBar.Brand style={{ marginRight: "20" }}>
                    <img
                        className="cisa-main-logo"
                        src={cisaLogoImg}
                        alt="CISA Logo"
                    />
                    <img
                        className="malware-main-logo"
                        src={malwareLogoImg}
                        alt="Malware Logo"
                    />
                </NavBar.Brand>
                <Button
                    style={{ width: "102px", marginLeft: "auto" }}
                    controlid="logoutButton"
                    type="button"
                    onClick={() => handleLogout()}
                >
                    Exit
                </Button>
            </NavBar >
            <div id="content"><br ></br><h1 style={{ textAlign: "center" }}>{messagePermission}</h1></div>
        </div>
    );
}

export default NoAccountPage;
