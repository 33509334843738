import { faL } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

const ErrorDisplay = (props) => {
    let errorElement = "";
 
    const getErrorElement = () => {
        const { name } = props.field;
        const { reqObj } = props.field;
        const errors = props.errors;

        if (errors[name]) {
            switch (errors[name].type) {
                
                case "required":
                    if (name === "disclaimer_ind") {
                        errorElement = (
                           <p className="help-text"> Selecting this checkbox is required. </p>
                        );
                    } else if(name === "url_text" || name === "files") {
                        errorElement = (
                            <p className="help-text"> Enter a URL or upload a file, one of these are required. </p> 
                        );
                    } else{
                        
                        errorElement = (
                            <p className="help-text"> This field is required. </p>
                         );
                    }
                    break;

                case "maxLength":
                    errorElement = (
                        <p className="help-text">
                            {" "}
                  This field exceeds {reqObj.maxLength} characters.
                        </p>
                    );
                    break;

                    case "minLength":
                    errorElement = (
                        <p className="help-text">
                            {" "}
                  This field requires {reqObj.minLength} or more characters.
                        </p>
                    );
                    break;

                    case "checkValidate":
                    errorElement = <p className="help-text"> {props.field.errMsg} </p>;
                    break;

                default:
            }
        }
        return errorElement;
    };

    return getErrorElement();

}

export default ErrorDisplay;