import React from 'react';
import Collapse from "@material-ui/core/Collapse";
import EmailAnalysisSectionHeader from '../EmailAnalysisSectionHeader';

import reactStringReplace from "react-string-replace";

const EmailAddresses = ({emailAddressesOpen, emailAddressesData, handleEmailAddressesOpen}) => {
  
  const getEmailsRegex = (emailAddressesData) => {
    let regexString = "(";
    let addressWithEscapedSpecialChars = "";
    const maxInd = emailAddressesData.email_address_counts.length - 1;

    emailAddressesData.email_address_counts.map( (emailAddressObj, ind) => {
      addressWithEscapedSpecialChars = emailAddressObj.email_address.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
      if( ind < maxInd ) {
        regexString += addressWithEscapedSpecialChars + "|";
      } else {
        regexString += addressWithEscapedSpecialChars + ")";
      }
    });
    const reg = new RegExp(regexString, 'g');
    return reg;
  }

  return (
    <div className="section-container">
      <EmailAnalysisSectionHeader
        sectionId="Email Addresses"
        open={emailAddressesOpen}
        handleClick={() => handleEmailAddressesOpen()}
      />
      <Collapse in={emailAddressesOpen} timeout="auto" unmountOnExit>
        { (emailAddressesData && emailAddressesData.email_address_counts &&
        emailAddressesData.email_address_counts.length > 0) 
          ? 
            <table className="ear-table">
              <tbody>
                  <tr>
                    <td className="ear-key">Email Address Counts</td>
                    <td className="ear-val">
                      <table className="ear-sub-table">
                        <tbody>
                          <tr>
                            <th width="10%">Count</th>
                            <th>Email Address</th>
                          </tr>
                          {emailAddressesData.email_address_counts.map( (emailAddress, i) => {
                            return (
                              <tr key={i}>
                                {Object.values(emailAddress).map( (cell, ind) => {
                                  return <td key={ind}>{cell}</td>
                                })}
                              </tr>
                            )
                          })}
                        </tbody>
                      </table> 
                    </td>
                  </tr>
                  <tr>
                    <td className="ear-key">In Context</td>
                    <td style={{padding: "4px 8px 4px 3px"}}>
                      <table className="in-context-table">
                        <tbody>
                          {Object.entries(emailAddressesData.in_context).map( ([key, val], ind) => {
                            return (
                              <tr key={ind} style={{borderWidth: "0"}}>
                                <td className="in-context-key">{key}:</td>
                                <td className="in-context-val">
                                  {reactStringReplace(val, getEmailsRegex(emailAddressesData), (match, i) =>
                                      <span key={i} className="highlight-match yellow">{match}</span>
                                  )}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </td>
                  </tr>
              </tbody>
            </table> 
          : 
            <div className="empty-ear-section">
              No email addresses found.
              <hr />
            </div>
        }
      </Collapse>
    </div>
  );
}

export default EmailAddresses;
