import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Icon} from "semantic-ui-react";

class Confirmation extends Component {
  render() {
    window.scrollTo(0,0);
    return (
      <div className="message-with-icon-container text-center">
        <Icon name="check circle outline" size="massive" style={{color: "#3e603d", fontSize: "9.5em"}}/>
        <h1>Thank you!</h1>
        <p>Your submission successfully went through.</p>
        <Link to="/submissionList">View All Submissions</Link>
        <span> | </span>
        <Link to="/submission"> Submit Another</Link>
      </div>
    );
  }
}

export default Confirmation;