import React, { Component } from 'react'
import "../../../../assets/css/FileAnalysisReport/TransactionInfo.css";
import { STATUS } from "../../../../utils/status";
import {connect} from "react-redux";
import ThreatMeter from "./ThreatMeter";
import {Button} from "react-bootstrap";

class TransactionInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
          showMore: true
        }
      }

    handleFileOrURL(fileOrURL) {
        if(fileOrURL.length > 100) {
            if(this.state.showMore) {
                return fileOrURL.substring(0, 100);
            } else {
                return fileOrURL;
            }
        } else {
            return fileOrURL;
        }
    }

    displayValue(fieldName, fieldValue) {
        if (fieldName !== 'Status') {
            return <span>{fieldValue}</span>
        }

        let className = '';
        if (fieldValue.toLowerCase() === STATUS.COMPLETED.toLowerCase()) {
            className = 'completed-status'
        } else if (fieldValue.toLowerCase() === STATUS.IN_PROGRESS.toLowerCase()) {
            className = 'in-progress-status'
        }

        return <span className={className}>{fieldValue}</span>
    }

    render() {
        const inputThreat = parseFloat(this.props.currentTinfo.threat);
        const threat = !isNaN(inputThreat) && 0 <= inputThreat
            ? inputThreat
            : undefined;
        const sha256 = this.props.currentTinfo.sha256 ? this.props.currentTinfo.sha256 : 'N/A';
        const fileType = this.props.currentTinfo.fileType ? this.props.currentTinfo.fileType : 'N/A';
        const fileNameOrUrl = this.props.currentTinfo.fileNameOrUrl ? this.props.currentTinfo.fileNameOrUrl : 'N/A';

        const tid = this.props.currentTinfo.tid ? this.props.currentTinfo.tid : 'N/A';
        const date = this.props.currentTinfo.submittedDate ? this.props.currentTinfo.submittedDate : 'N/A';
        const status = this.props.currentTinfo.status ? this.props.currentTinfo.status : 'N/A';
        const fieldList = [
            { name: 'SHA-256', value: sha256 },
            { 
                name: (fileType==='URL') ? 'URL' : 'Filename', 
                value: fileNameOrUrl
            },
            { name: 'TID', value: tid },
            { name: 'Submitted Date', value: date },
            { name: 'Status', value: status }
        ]

        return (
            <div className="transaction-info-container" id={'transaction-info'}>
                <table className={'info-table'}>
                    <tbody >
                        <tr>
                            <td className={'info-column'}>
                                {fieldList.map((field, index) => {
                                    return (
                                        <div key={index} style={{ lineHeight: '2' }}>
                                            <span style={{ fontWeight: 'bold' }}>{field.name}: </span>
                                            <span style={{ wordBreak: 'break-all'}}>
                                                {field.name === "URL" || field.name === "Filename" 
                                                    ?
                                                        <span>
                                                            {this.handleFileOrURL(field.value)}
                                                            {field.value.length > 100 &&
                                                                <Button 
                                                                    onClick={()=>this.setState({showMore: !this.state.showMore})}
                                                                    className={'url-show-button'}
                                                                >
                                                                    {this.state.showMore ? "..." : "..."}
                                                                </Button>
                                                            }
                                                        </span>
                                                    :
                                                        this.displayValue(field.name, field.value)
                                                }
                                            </span>
                                        </div>
                                    )
                                })}
                            </td>
                            {/* <td className={'threat-column'}>
                                <ThreatMeter threat={threat}/>
                            </td> */}
                        </tr>
                    </tbody>
                </table>
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        currentTinfo: state.analysisReducer.currentTinfo
    }
}

export default connect(mapStateToProps, null)(TransactionInfo);
