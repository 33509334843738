import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch, } from "react-router-dom";
import "./../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { TopNav } from "./../layout";
import { Search } from "./../search";
import { Confirmation, Deny, SubmissionForm } from "../SubmissionForm";
import { Login, Register } from "./../login";
import MifrPage from "./../report/MifrReportPage";
import Home from "../home/Home";
import SubmissionList from "../SubmissionList/SubmissionList";
import IdleTimer from "react-idle-timer";
import { SessionExpireWarning } from "../timeout/ExpireWarningModal";
import FileAnalysisReport from "../report/FileAnalysisReport";
import { hasMarAccess, getInitialFilter, hasYaraAccess, isVettedUserFormAdmin } from '../../utils/PermissionUtil.js';
import { logout } from "../../utils/Logout";
import YaraPage from "../yara/YaraPage";
import YaraCreate from "../yara/add/YaraCreate.jsx";
import MarForm from "../mar/form/MarForm";
import MarList from "../mar/list/MarList";
import MarTemplate from "../mar/template/MarTemplate";
import IPAddressPage from "../mar/template/ipaddress/IPAddressPage";
import DomainPage from "../mar/template/domain/DomainPage";
import EmailAddressPage from "../mar/template/emailAddress/EmailAddressPage";
import EmailMessagePage from "../mar/template/emailMessage/EmailMessagePage";
import WhoisQuery from "../DomainQuery/WhoisQuery";
import NsLookup from "../DomainQuery/NsLookup";
import { AddVettedUser } from "../AddVettedUser";
import FileObjectPage from "../mar/template/file/FileObjectPage";
import InitiateMARWarning from "../SubmissionList/utils/InitiateMARWarning";
import { connect } from "react-redux";
import * as actionTypes from "../../redux/actions/marActions";
import PromoteDemoteLogs from "../yara/logs/PromoteDemoteLogs";
import { withRouter } from "react-router";
import keycloak from "../../index";
import TermServ from "../login/TermServ";
import * as httpCalls from "../../services/httpCalls.js";
import * as apiConnection from "../../services/apiConnection";

import NoAccountPage from "../layout/NoAccountPage";
const getAuth = () => {
  let userStatus;
  try {
    //disable old Malwareweb app log in page by setting userStatus = true
    userStatus = true; //orig code keep just incase     userStatus = localStorage.getItem("isAuthenticated");

    // console.log("mng_auth " + JSON.stringify(userStatus));
  } catch (err) {
    console.error("Auth err: " + err);
  }
  return userStatus;
};

const RequireAuth = ({ children }) => {
  if (!getAuth()) {
    return <Redirect to="/login" />;
  }
  return children;
};

const idleTimeoutInSeconds = process.env.REACT_APP_IDLE_TIMEOUT;
const warningTimeoutInSeconds = process.env.REACT_APP_WARNING_TIMEOUT;
// unused; only used for debugging idle time running
// console.log('App idle timeout',idleTimeoutInSeconds);
// console.log('Warning timeout',warningTimeoutInSeconds);



class AppContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      hasRuleBeenEdited: false,
      termOfService: props.termService
    }
    this.onActive = this.onActive.bind(this);
    this.onIdle = this.onIdle.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.agreeTermOfService = this.agreeTermOfService.bind(this);

    // unused; only used for debugging idle time running
    // this.idleTimer = null;
    // this.idleIntervalId = null;

  }

  onActive(e) {
    // console.log('user is active');
  }

  onIdle(e) {
    // console.log('user is idle');
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  handleLogout() {

    this.setState({ showModal: false });
    this.props.history.push('/home'); // go to home page before logging out 
    logout();
  }

  closeInitiateMARWarning = () => {
    this.props.setDisplayInitiateMarWarning(false);
  }

  closeDownloadTidWarning = () => {
    this.props.setDisplayDownloadTidWarning(false);
  }
  /* unused; only used for debugging idle time running */
  // componentDidMount() {
  //   this.idleIntervalId = setInterval(() => {
  //     console.log('idle till warning:', Math.floor(this.idleTimer.getRemainingTime()/1000))
  //       },1000);
  //
  // }
  // componentWillUnmount() {
  //   clearInterval(this.idleIntervalId);
  // }

  agreeTermOfService(termOfService) {

    if (termOfService === "1" || termOfService === 1) {

      return true;  //do nothing exit function
    }

    if (termOfService === null || termOfService == undefined ) {

      this.insertTermsService();
      this.setState({ termOfService: 1 }); // 1 means user agree to terms of service

    } else if (termOfService === "0" || termOfService === 0) {

      this.updateTermsService();
      this.setState({ termOfService: 1 });
    }

    localStorage.setItem(keycloak.tokenParsed.email+"tos", "1"); //localStorage needs value to be a string

  }

  updateTermsService = () => {

    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + keycloak.token
    };

    let data = {
      "user_id": keycloak.tokenParsed.preferred_username,
      "tos_indicator": 1
    }

    let apiUrl = apiConnection.updateTermsOfService();

    httpCalls.configureAxiosRequest(apiUrl, header, "PUT", data)
      .then(response => {
        //console.log("http response", response);
        if (response.status >= 200 && response.status <= 299) {

          this.setState({ termOfService: 1 });

          //return response.json(); //wait for data then send data to next step
        }
        else {
          throw new Error(response.status + " - " + response.statusText);
        }
      })
      .catch(err => {
        console.warn("TermsService : " + err);
      });
  }

  insertTermsService = () => {

    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + keycloak.token
    };

    let data = {
      "user_id": keycloak.tokenParsed.preferred_username,
      "tos_indicator": 1
    }

    let apiUrl = apiConnection.insertTermsOfService();

    httpCalls.configureAxiosRequest(apiUrl, header, "POST", data)
      .then(response => {
        //console.log("http response", response);
        if (response.status >= 200 && response.status <= 299) {

          this.setState({ termOfService: 1 });

          //return response.json(); //wait for data then send data to next step
        }
        else {
          throw new Error(response.status + " - " + response.statusText);
        }
      })
      .catch(err => {
        console.warn("TermsService : " + err);
      });

  }

  render() {
    let { termOfService } = this.state;

    return (
      <>
        {
        (termOfService === "1" || termOfService === 1) ?
        
        (getInitialFilter() === "") ? <NoAccountPage/> :
          <Router>
            <div className="App">
              <IdleTimer
                // ref={ref => { this.idleTimer = ref }}  // unused; only used for debugging idle time running
                element={document}
                onActive={this.onActive}
                onIdle={this.onIdle}
                timeout={idleTimeoutInSeconds * 1000}
              />

              <TopNav hasRuleBeenEdited={this.state.hasRuleBeenEdited}></TopNav>
                <div id="content">
                  <Switch>
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/register" component={Register} />

                    <RequireAuth>
                      <Route path="/home" component={Home} />
                      <Route exact path="/" component={Home}>
                        <Redirect to="/home" />
                      </Route>
                      <Route path="/report/" component={FileAnalysisReport} />
                      <Route exact path="/submissionList" component={SubmissionList} />
                      <Route exact path="/search" component={Search} />
                      <Route exact path="/submission" component={SubmissionForm} />
                      <Route exact path="/mifr" component={MifrPage} />
                      <Route exact path="/submission/deny" component={Deny} />
                      <Route exact path="/submission/confirm" component={Confirmation} />
                      {
                        hasYaraAccess() &&
                        <>
                          <Route exact path="/analystTools/yara" render={() => <YaraPage hasRuleBeenEdited={(boolVal) => this.setState({ hasRuleBeenEdited: boolVal })} />} />
                          <Route exact path="/analystTools/yara/create" component={YaraCreate} />
                          <Route exact path="/analystTools/yara/logs" component={PromoteDemoteLogs} />
                        </>
                      }
                      {
                        hasMarAccess() &&
                        <>
                          <Route exact path="/analystTools/mar/create" component={MarForm} />
                          <Route exact path="/analystTools/mar/list/" component={MarList} />
                          <Route exact path="/mar/template" component={MarTemplate} />
                          <Route exact path="/mar/file" component={FileObjectPage} />
                          <Route exact path="/mar/ipaddress" component={IPAddressPage} />
                          <Route exact path="/mar/domain" component={DomainPage} />
                          <Route exact path="/mar/email-message" component={EmailMessagePage} />
                          <Route exact path="/mar/email-address" component={EmailAddressPage} />
                        </>
                      }
                      {
                        isVettedUserFormAdmin() &&
                        <Route exact path="/analystTools/addVettedUser" component={AddVettedUser} />
                      }
                      <Route exact path="/analystTools/domainQuery/whois" component={WhoisQuery} />
                      <Route exact path="/analystTools/domainQuery/nsLookup" component={NsLookup} />
                    </RequireAuth>
                  </Switch>
                </div>
              {this.state.showModal &&
                <SessionExpireWarning
                  showModal={this.state.showModal}
                  handleClose={this.handleCloseModal}
                  handleLogout={this.handleLogout}
                  timeout={warningTimeoutInSeconds}
                />
              }
              {this.props.displayInitiateMARWarning &&
                <InitiateMARWarning
                  showModal={this.props.displayInitiateMARWarning}
                  handleClose={this.closeInitiateMARWarning}
                />
              }
            </div>
          </Router>
          :
          <TermServ agreeTermOfService = {this.agreeTermOfService} termOfService = {this.state.termOfService} />
        }
      </>
    );
  }
}

const mapStateToProps = state => {
  const state_props = {
    displayInitiateMARWarning: state.marReducer.displayInitiateMARWarning
  }
  return state_props;
}

const mapDispatchToProps = dispatch => {
  const dispatch_props = {
    setDisplayInitiateMarWarning: (value) => dispatch({ type: actionTypes.SET_DISPLAY_INITIATE_MAR_WARNING, value: value })
  }
  return dispatch_props;
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppContainer));
