
const getFormDataForSubmission = (data) => {
    let formdata = new FormData();
    for (let key in data) {
      if (key !== "files" && key !== "disclaimer_ind") {
        formdata.append(key, typeof data[key] == "string" ? data[key].trim() : data[key] );
      }
    }

    formdata.append("disclaimer_ind", 1);
    const files = data["files"];

    // if regular submission, files var will be of type file
    // if dropped file submission, files var will of type string

  if (data.source !== 'url') {
    if(typeof files != "string") {
        for (let i = 0; i < files.length; i++) {
          formdata.append("files", files[i], files[i].name);
        }
      } else {
        formdata.append("files", data["files"]);
      }
    } else {
      formdata.delete("url_text", data["url_text"]);
    }

    return formdata;
  };
export default getFormDataForSubmission;