import React, { useState, useEffect } from 'react';
import * as apiConnection from "../../../services/apiConnection.js";
import * as httpCalls from "../../../services/httpCalls.js";
import { Link, useHistory } from "react-router-dom";
import * as actionTypes from "../../../redux/actions/analysisActions";
import * as marActionTypes from "../../../redux/actions/marActions";
import { downloadSidTidInitialState, downloadSidTidActionTypes } from "../../../redux/actions/DownloadActionsState";
import { useDispatch } from "react-redux";
import keycloak from "../../../index";
import { hasMarAccess, isMSISACAnalyst, isMngConsumer,isMsIsacConsumer, isAnalystAdmin, hasMetricsAccess } from "../../../utils/PermissionUtil";
import store from '../../../redux/store/store';
import DownloadReducer from '../../../redux/reducers/DownloadReducer';
import TidsDownloadPopup from './TidsDownloadPopup';
import DownloadTidWarning from './DownloadTidWarning'
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
} from "@material-ui/core";
import "../../../assets/css/TransMetaTable.css";
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';

  const TransMeta = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [tidsDownloadPopupTrigger, setTidsDownloadPopupTrigger] = useState(false);
  const [tidsDownloadWarningPopupTrigger, setTidsDownloadWarningPopupTrigger] = useState(false);
  const [isPopupSubmittedSuccessful, setIsPopupSubmittedSuccessful] = useState(false);
  const [fileType, setFileType] = useState(null);
  const [showMore, setShowMore] = useState(true);
  const [failedSidSelected, setFailedSidSelected] = useState(false);

  const handleFileUrl = (fileUrl) => {
    if(fileUrl.length > 100) {
        if(showMore) {
            return fileUrl.substring(0, 100);
        } else {
            return fileUrl;
        }
    } else {
        return fileUrl;
    }
  }

  useEffect(() => {
    // console.log("Loading Transaction and Meta data...");
    getRecentTransMeta();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let header = {
    Accept: "application/json",
    "Content-Type": "application/json",
    'Authorization': 'Bearer ' + keycloak.token
  };

  const getRecentTransMeta = () => {
    const method = "PUT";
    const url = apiConnection.getTranMetaListUrl();
    const data_in = {
      "sid": props.sid,
      "delta": -(new Date().getTimezoneOffset()),
      "limit": "99",
      "order_by": "created_ts",
      "file_metadata": "True"
    };

    httpCalls
      .configureAxiosRequest(
        url,
        header,
        method,
        data_in
      )
      .then((res) => {
        const input_json_str = Object.keys(res.data)[0];
        const input_json_obj = res.data[input_json_str];
  
        let value=""; let in_obj={}; let inner_obj ={};  // let key=""; 
        for (let i=0; i<input_json_obj.length; i++) {
          in_obj=input_json_obj[i]
          inner_obj=Object.keys(in_obj);
          value=inner_obj[0];
          setFileType(in_obj[value]["transaction_file_data"]["file_type"])
          // let file_name = in_obj[value]["transaction_file_data"]["original_file_name"];
          // file_name = (file_name).slice(-4)
          // if (file_name === ".zip" ) {
          //   input_json_obj[i][value]["transaction_file_data"]["archive"] = true;
          // } else {
          //   input_json_obj[i][value]["transaction_file_data"]["archive"] = false;
          // }
        }

        setRows(input_json_obj);
        props.reloadSIDStatus();
        // console.log("Table Data Complete");
        // setError(false);
      })
      .catch((err) => {
        if (err.response) {
          console.error("Recent TransMeta Response err: " + err.response.data.errors);
        }
        else if (err.result) {
          console.error("Recent TransMeta Result err: " + err.result.data.errors);
        }
        else {
          console.error("Recent TransMeta err: " + err);
        }
      });
  };

  const openTidReportsHandle = (stateObj) => {
    dispatch({ type: actionTypes.OPEN_TID, tinfo: stateObj })
    return true;
  }

  const downloadFile = (zip_name, zip_password, zip_tid_list, zip_sid) => {
    const method = "PUT";
    const url = apiConnection.downloadTidFilesAsZip();
    const data_in = {
      "tids": zip_tid_list,
      "zip_password": zip_password,
      "sid": props.sid,
      "user_id": keycloak.tokenParsed.preferred_username
    };
    // console.log("TID List>>>  ", zip_tid_list, "Zip Password>>>  ", zip_password)
    httpCalls
      .configureAxiosRequestDownload(
        url,
        header,
        method,
        data_in
      )
      .then((res) => {
        if (res.data) {
          const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = downloadUrl;
          const zipFileName = zip_name + '.zip';
          link.setAttribute('download', zipFileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          setIsPopupSubmittedSuccessful(true);
        }
      })
      .catch((err) => {
        console.error("Download Tid files err: " + err);
        setIsPopupSubmittedSuccessful(false);
      });
  }

  const handleSidDownloadCheckbox = (e, rows, sid) => {
    const { name, value, checked } = e.target;
    setFailedSidSelected(checked);

    for (let i = 0; i < rows.length; i++) {
      for (var key in rows[i]) {
        if (rows[i][key]['transaction_file_data']['drop_file_level'] == null) {
          let status = rows[i][key]['transaction_file_data']['transaction_status_cd'];
          // let archive = rows[i][key]['transaction_file_data']['archive'];

          if(checked) {
            document.getElementById(key).checked = true;
            handleDownloadCheckbox(e, key, status, sid);
          } else {
            document.getElementById(key).checked = false;
            handleDownloadCheckbox(e, key, status, sid);
          }

          // if (!archive) {
          //   if (checked) {
          //     document.getElementById(key).checked = true;
          //     handleDownloadCheckbox(e, key, status, sid);
          //   } else {
          //     document.getElementById(key).checked = false;
          //     handleDownloadCheckbox(e, key, status, sid);
          //   }
          // }
          // if(archive && props.sidStatus==="Failed") {
          //   if(checked) {
          //     setFailedSidSelected(true);
          //   } else {
          //     setFailedSidSelected(false);
          //   }
          // }
        }
      }
      // alert (JSON.stringify(store.getState().DownloadReducer.sidTidsInfo ));
    }
  }

  const handleDownloadCheckbox = (e, tid, status, sid) => {
    const { name, value, checked } = e.target;
    if (checked) {
      dispatch({ type: downloadSidTidActionTypes.ADD_TID, payload: { sid: sid, tid: tid } });
    } else {
      dispatch({ type: downloadSidTidActionTypes.REMOVE_TID, payload: { sid: sid, tid: tid } });
    }
    // dispatch({type: downloadSidTidActionTypes.TOGGLE_COMPLETED, payload: {sid: sid}})
    // alert (JSON.stringify(store.getState().DownloadReducer.sidTidsInfo ));
  }

  const handleZipChecked = (tid, sid) => {
    dispatch({ type: downloadSidTidActionTypes.ADD_TID, payload: { sid: sid, tid: tid } });
  }

  const handleZipUnchecked = (tid, sid) => {
    dispatch({ type: downloadSidTidActionTypes.REMOVE_TID, payload: { sid: sid, tid: tid } });
  }

  const handleTidForMarChangeCheckbox = (e, tid, status) => {
    const { name, value, checked } = e.target;
    if (checked) {
      if (status === "Completed") {
        dispatch({ type: marActionTypes.ADD_TID_FOR_MAR, tid: tid })
      }
    } else {
      if (status === "Completed") {
        dispatch({ type: marActionTypes.REMOVE_TID_FROM_MAR, tid: tid })
      }
    }
  }

  const downloadTids = (sid) => {
    // failed archive
    // if(failedSidSelected) {
    //   setTidsDownloadPopupTrigger(true);
    //   setTidsDownloadWarningPopupTrigger(false);
    //   return;
    // }

    let sidTids = null;
    try {
      sidTids = store.getState().DownloadReducer ? store.getState().DownloadReducer.sidTidsInfo.sid_tids : {};
    } catch (error) {
      dispatch({ type: downloadSidTidActionTypes.CLEAR_DOWNLOAD_TID_LIST });
      console.log('sid_tids was null, but now it is ' + store.getState().DownloadReducer.sidTidsInfo.sid_tids + '.');
    }

    let selectedTids = [];
    if (sidTids) {
      selectedTids = Object.keys(sidTids).map(
        function (key) {
          if (key === sid) {
            // alert ("243-->  "+JSON.stringify(store.getState().DownloadReducer.sidTidsInfo ));
            dispatch({ type: downloadSidTidActionTypes.SET_IS_TID_SELECTED_FOR_THIS_SID, value: true });
            setTidsDownloadPopupTrigger(true);
            setTidsDownloadWarningPopupTrigger(false);
            // alert ("245-->  "+JSON.stringify(store.getState().DownloadReducer.sidTidsInfo ));
            return sidTids[key];
          } else {
            // alert("Please select at lease one TID for SID: "+ sid +".");
            setTidsDownloadPopupTrigger(false);
            setTidsDownloadWarningPopupTrigger(true);
            dispatch({ type: downloadSidTidActionTypes.SET_IS_TID_SELECTED_FOR_THIS_SID, value: false });
            return [];
          }
        }
      )[0];
    }
    if (!selectedTids || selectedTids.length === 0) {
      setTidsDownloadPopupTrigger(false);
      setTidsDownloadWarningPopupTrigger(true);
    }
  }

  const initiateMar = (e) => {
    const selectedTids = store.getState().marReducer ? store.getState().marReducer.tidsForMar : [];

    if (!selectedTids || selectedTids.length === 0) {
      dispatch({ type: marActionTypes.SET_DISPLAY_INITIATE_MAR_WARNING, value: true })
    } else {
      history.push({
        pathname: "/analystTools/mar/create",
        state: {
          isFromInitiateMar: true
        }
      })
    }
  }

  // const showDownloadCheckbox = (archive) => {
  //   if ((isAnalystAdmin() || isMSISACAnalyst()) && !archive) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // const checkSidForFailedArchive = () => {
  //   setFailedSidSelected(false);
  // }

  const setZipNamePassword = (zipNamePassword) => {
    if (zipNamePassword.popup_flag) {
      downloadFile(zipNamePassword.zip_name, zipNamePassword.zip_password, zipNamePassword.zip_tid_list, zipNamePassword.zip_sid)
      for (let i = 0; i < zipNamePassword.zip_tid_list.length; i++) {
        // dispatch({type: downloadSidTidActionTypes.REMOVE_TID, payload: {sid: sid, tid: tid }})
        dispatch({ type: downloadSidTidActionTypes.REMOVE_TID, payload: { sid: zipNamePassword.sid, tid: zipNamePassword.zip_tid_list[i] } })
      }
    }
  }

  // after GA, this should be done in the database
  const getVerdictColor = (verdict) => {
    switch (verdict) {
      case 'malicious':
        return "#99001A";
      case 'suspicious':
        return "#C29C28";
      case 'clean':
        return "#669900";
      case 'not_available':
        return "#8B7E74";
      default:
        return;
    }
  }

  const handleVerdictValue = (verdict) => {
    // if verdict is null, return empty string
    if(!verdict) {
        return " ";
    }if(verdict==="not_available") {
        verdict="N/A";
    }
    return verdict.toUpperCase();
  }
  
  const checkIfArchive = (verifiedFileExtension) => {
    const archiveExtensions = [
      "zip archive",
      "tar archive",
      "compressed data",
      "archive data"
    ]
    if(verifiedFileExtension !== null) {
      for(var i=0; i<archiveExtensions.length; i++) {
        let archiveIndicator = archiveExtensions[i];
        if(verifiedFileExtension.toLowerCase().includes(archiveIndicator)){
          return true;
        }
      }
    }
    return false;
  }

  if (tidsDownloadWarningPopupTrigger) {
    return (
      <div >
        <DownloadTidWarning
          tidsDownloadWarningPopupTrigger={tidsDownloadWarningPopupTrigger}
          setTidsDownloadWarningPopupTrigger={setTidsDownloadWarningPopupTrigger}
          sid={props.sid}
        // isPopupSubmittedSuccessful={isPopupSubmittedSuccessful}
        >
        </DownloadTidWarning>
      </div>
    )
  } else if (tidsDownloadPopupTrigger) {
    return (
      <div >
        <TidsDownloadPopup
          tidsDownloadPopupTrigger={tidsDownloadPopupTrigger}
          setTidsDownloadPopupTrigger={setTidsDownloadPopupTrigger}
          set_zip_name_password={setZipNamePassword}
          sid={props.sid}
          isPopupSubmittedSuccessful={isPopupSubmittedSuccessful}
          // checkSidForFailedArchive={checkSidForFailedArchive}
        >
        </TidsDownloadPopup>
      </div>
    )
  } else {
    return (
      <div >
        <Table size='small'>
          <TableHead className="tids_header">
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Comments</TableCell>
              <TableCell
                colSpan="10"
                style={{ textAlign: "left", fontWeight: "bold", width: "200px" }}>
                {props.description}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Submitted</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>TID</TableCell>
              {fileType === "URL" ?
                <TableCell style={{ fontWeight: "bold" }}>URL</TableCell>
                :
                <TableCell style={{ fontWeight: "bold" }}>Filename</TableCell>
              }
              {/* {hasMetricsAccess() && */}
                <TableCell style={{ width: "10px" }}>
                  <input
                    key={props.sid}
                    name={"sidForDownload"}
                    className="form-checks"
                    type="checkbox"
                    value={props.sid}
                    onChange={(e) => handleSidDownloadCheckbox(e, rows, props.sid)}
                    defaultChecked={false}
                  />
                </TableCell>
              {/* } */}
              {isAnalystAdmin() || isMSISACAnalyst() || isMngConsumer() || isMsIsacConsumer()?
              <>
                <TableCell>
                  <DownloadRoundedIcon 
                    // onClick={() => setTidsDownloadPopupTrigger(true)}
                    onClick={() => downloadTids(props.sid)}
                  />
                </TableCell>
                <TableCell>{' '}</TableCell>
                </>
                : 
                  <>
                    <TableCell>{' '}</TableCell>
                    <TableCell>{' '}</TableCell>   {/*this is needed for column alignment */}
                    <TableCell>{' '}</TableCell>
                 </>
              }
              <TableCell style={{ fontWeight: "bold" }}>SHA-256</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Drop Count</TableCell>
              {/* <TableCell style={{ fontWeight: "bold" }}>Threat Score</TableCell> */}
              <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Verdict</TableCell>
              {/* {
                hasMarAccess() &&
                <TableCell style={{ fontWeight: "bold" }}>Initiate MAR</TableCell>
              } */}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              const obj = Object.keys(row)[0];
              if (row[obj]['transaction_file_data']['drop_file_level'] == null) {
                const createdTimestamp = row[obj]['transaction_file_data']['created_ts'];
                const tid = row[obj]['transaction_file_data']['tid'];
                const sid = row[obj]['transaction_file_data']['sid'];
                let fileNameOrUrl = null;
                const fileType = row[obj]['transaction_file_data']['file_type'];
                if (fileType === 'URL') {
                  fileNameOrUrl = row[obj]['transaction_file_data']['url'];
                } else {
                  fileNameOrUrl = row[obj]['transaction_file_data']['original_file_name'];
                }
                // const archive = row[obj]['transaction_file_data']["archive"];
                // const obj2 = Object.keys(row)[0];
                const sha256 = row[obj]['file_metadata']['sha256'];
                const status = row[obj]['transaction_file_data']['transaction_status_cd'];
                // convert string to JSON
                const dropFileCnt = JSON.parse(row[obj]['transaction_file_data']['drop_file_cnt']);
                const verifiedFileExtension = row[obj]['transaction_file_data']['verified_file_extension'];
                const threat = row[obj]['transaction_file_data']['threat_score'];
                const verdict = row[obj]['transaction_file_data']['threat_score'];
                
                const stateObj = {
                  sid: props.sid,
                  tid: tid,
                  fileNameOrUrl: fileNameOrUrl,
                  fileType: fileType,
                  sha256: sha256,
                  submittedDate: createdTimestamp,
                  threat: threat,
                  verdict: verdict,
                  status: status
                }
                
                return (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {createdTimestamp}
                    </TableCell>
                    <TableCell>
                      {(checkIfArchive(verifiedFileExtension) || status==="Error")
                      ? tid 
                      : <Link to={'/report/'} onClick={() => openTidReportsHandle(stateObj)}>
                          {tid} 
                        </Link>
                      }
                    </TableCell> 
                    <TableCell style={{wordWrap: "break-word", maxWidth: "300px"}}>
                    {handleFileUrl(fileNameOrUrl)}
                      {
                        fileNameOrUrl.length > 100 &&
                          <button  
                            className={'url-show-button'}   
                            onClick={()=>setShowMore(!showMore)}
                          >
                          {showMore ? "..." : "..."}
                          </button>
                      }
                    </TableCell>

                    {/*  ask Neerja if TID download option is only for Analyst, Admin, and MSIAC Analyst? */}
                    {
                      //  (isAnalystAdmin() || isMSISACAnalyst()) ?
                      // showDownloadCheckbox(archive) ?
                       <TableCell style={{ width: "10px" }}>
                        <input
                          key={tid}
                          id={tid}
                          name={"tidForDownload"}
                          className="form-checks"
                          type="checkbox"
                          value={tid}
                          onChange={(e) => handleDownloadCheckbox(e, tid, status, sid)}
                          defaultChecked={false}
                          style={{margin: "0.6rem 0 .6rem 0"}}
                        />
                      </TableCell>
                      // : 
                      // <TableCell>
                      //   <input
                      //     key={tid}
                      //     id={tid}
                      //     name={"tidForDownload"}
                      //     className="form-checks"
                      //     type="checkbox"
                      //     value={tid}
                      //     onChange={(e) => handleDownloadCheckbox(e, tid, status, sid)}
                      //     defaultChecked={false}
                      //     checked={
                      //       failedSidSelected && props.sidStatus==="Failed" 
                      //       ? handleZipChecked(tid, sid) 
                      //       : handleZipUnchecked(tid, sid)
                      //     }
                      //     style={{margin: "0.6rem 0 .6rem 0", visibility: "hidden"}}
                      //   />
                      // </TableCell>
                      
                      //this is needed for column alignment
                    }
                    <TableCell>{' '}</TableCell>
                    <TableCell>{' '}</TableCell>
                    
                    <TableCell>{sha256}</TableCell>
                    <TableCell>{status}</TableCell>
                    <TableCell>{dropFileCnt ? dropFileCnt.totalCount : ""}</TableCell>
                    
                    {/* <TableCell>{threat}</TableCell> */}
                    <TableCell align={'center'}>
                      <div className="verdict" style={{backgroundColor: getVerdictColor(verdict)}} >
                          {handleVerdictValue(verdict)}
                      </div>
                    </TableCell>
                    {/* {
                      hasMarAccess() &&
                      <TableCell>
                        <input
                          key={tid}
                          id={tid}
                          name={"tid"}
                          className="form-checks"
                          type="checkbox"
                          value={tid}
                          onChange={(e) => handleTidForMarChangeCheckbox(e, tid, status)}
                          defaultChecked={false}
                        />
                        <Button type={"button"} size={"lg"} onClick={(e) => initiateMar(e)} style={{ color: "grey" }}></Button>
                      </TableCell>
                    } */}
                  </TableRow>
                )
              }
            })}
          </TableBody>
        </Table>
      </div>
    );
  }
};

export default TransMeta;
