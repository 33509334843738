import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Icon} from "semantic-ui-react";
import {withRouter} from 'react-router';

class NoResult extends Component {
  render() {
    window.scrollTo(0,0);
    return (
      <div className="message-with-icon-container text-center">
        <Icon.Group style={{color: "rgb(161, 183, 183)", opacity: ".88"}}>
          <Icon name="circle outline" size="large"/>
          <Icon name="search" size="small" style={{paddingRight: "8px"}} />
        </Icon.Group>
        <h1>No Results Found</h1>
        <p>We couldn't find what you're looking for.</p>
        <Link to="/home">View Your Submissions</Link>
        <span> | </span>
        <Link to="/submissionList"> View All Submissions</Link>
      </div>
    );
  }
}

export default withRouter (NoResult);